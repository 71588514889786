<template>
  <div>
    <div class="backsOut">
      <div class="backs">
        <img src="@/assets/images/fh.png" alt="" @click="goback" />
        <span>用户协议</span>
      </div>
    </div>
    <div class="ertgf_box">
      <div class="title">丰收管家隐私政策</div>
      <div>
        钱金出行（以下或称“我们”）注重保护用户个人信息及个人隐私。本隐私政策解释了用户（“您”）个人信息收集（以下或称“信息”）和使用的有关情况，本隐私政策适用于钱金出行向您提供的所有相关服务（包括但不限于电子商务、网络资讯、网络社交、互联网金融服务等，以下称“钱金出行服务”或“服务”）。如果您不同意本隐私政策的任何内容，您应立即停止使用钱金出行服务。当您使用钱金出行提供的任一服务时，即表示您已同意我们按照本隐私政策来合法使用和保护您的个人信息。
      </div>
      <div class="title_bos">一、您个人信息的收集</div>
      <div>
        我们收集信息是为了向您提供更好以及更个性化的服务，并努力提高您的用户体验。我们收集信息的种类如下:
      </div>
      <div class="title_bos">1、您向我们提供的信息</div>
      <div>
        当您注册钱金出行账户及您在使用相关钱金出行服务时填写、提交及/或其他任何方式提供的信息，包括您的姓名、性别、出生年月日、身份证号码、护照姓、护照名、护照号码、电话号码、电子邮箱、收货地址、钱金出行钱包或网银在线账号、银行卡信息及相关附加信息（如您地址中的所在的省份和城市、邮政编码等）。您可以选择不提供某一或某些信息，但是这样可能使您无法使用钱金出行的许多特色服务。请您理解，我们使用您提供的信息是为了回应您的要求，为您在钱金出行购物或享受服务提供便利，完善钱金出行网站以及与您进行信息沟通。另外，我们可能会将您所提供的信息与您的钱金出行账户关联，用以识别您的身份。
      </div>
      <div class="title_bos">2、我们在您使用服务过程中获得的信息</div>
      <div>
        为了提高服务质量和用户体验，我们会留存您使用服务以及使用方式的相关信息，这类信息包括：
        （1）您的浏览器和计算机上的信息。在您访问钱金出行网站或使用钱金出行服务时，钱金出行系统自动接收并记录的您的浏览器和计算机上的信息（包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据）。
      </div>
      <div>（2）您的位置信息。</div>
      <div>（3）您的设备信息。</div>
      <div>
        （4）您的行为或交易信息。钱金出行可能会记录您访问钱金出行或使用钱金出行服务时所进行的操作以及您在钱金出行网站上进行交易的相关信息。
        钱金出行可能会记录您访问钱金出行或使用钱金出行服务时所进行的操作以及您在钱金出行网站上进行交易的相关信息。
        同时，为提高您使用钱金出行提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，我们可能会通过了解一些您的网络使用习惯、您常用的软件信息等手段来判断您账户的风险，并可能会记录一些我们认为有风险的链接（“URL”）。
      </div>

      <div class="title_bos">3、来自第三方的信息</div>
      <div>
        指在您注册钱金出行账户和使用服务过程中，您授权钱金出行可向钱金出行的关联方、合作伙伴获取其所收集的相关信息。这些信息包括但不限于您的身份信息、行为信息、交易信息、设备信息等，钱金出行会将此类信息汇总，用于帮助钱金出行向您提供更好以及更加个性化的服务或更好的预防互联网欺诈。
      </div>
      <div>您了解并同意，以下信息不适用本隐私政策：</div>
      <div>（1）您在使用钱金出行提供的搜索服务时输入的关键字信息；</div>
      <div>
        （2）信用评价、违反法律法规规定或违反钱金出行平台规则行为及钱金出行已对您采取的措施；
      </div>
      <div>
        （3）应法律法规要求需公示的企业名称等相关工商注册信息以及自然人经营者的信息。
      </div>
      <div>
        （4）其他您与钱金出行或钱金出行的关联方所签署的协议（包括在线签署的电子协议，例如《钱金出行用户注册协议》）以及钱金出行平台规则中明确约定或提示您不适用本隐私政策的与您有关的信息。
      </div>

      <div class="title_bos">二、我们对您个人信息的管理和使用</div>
      <div>
        为向您提供服务、提升我们的服务质量以及优化您的服务体验，我们会在符合法律规定下使用您的个人信息，并主要用于下列用途：
      </div>
      <div>1、向您提供您使用的各项服务，并维护、改进这些服务。</div>
      <div>
        2、向您推荐您可能感兴趣的内容，包括但不限于向您发出产品和服务信息，或通过系统向您展示个性化的第三方推广信息，或在征得您同意的情况下与钱金出行的合作伙伴共享信息以便他们向您发送有关其产品和服务的信息。如您不希望接收上述信息，可通过相应的退订功能进行退订。
      </div>
      <div>
        3、我们可能使用您的个人信息以验证身份、预防、发现、调查欺诈、危害安全、非法或违反与我们或其关联方协议、政策或规则的行为，以保护您、其他钱金出行用户，或我们或其关联方的合法权益。
      </div>
      <div>
        4、我们可能会将来自某项服务的个人信息与来自其他服务所获得的信息结合起来，用于为了给您提供更加个性化的服务使用，例如为让您通过购物拥有更广泛的社交圈而使用、共享或披露您的信息。
      </div>
      <div>
        5、我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，以展示我们的产品或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
      </div>
      <div>6、让您参与有关我们产品及服务的调查。</div>
      <div>7、经您同意或授权的其他用途。</div>
      <div class="title_bos">三、您个人信息的分享</div>
      <div>
        您的个人信息是我们为您提供服务的重要部分，我们会遵循法律规定对您的信息承担保密义务。除以下情形外，我们不会将您的个人信息披露给第三方：
      </div>
      <div>1、征得您的同意或授权。</div>
      <div>2、根据法律法规的规定或行政或司法机构的要求。</div>
      <div>
        3、出于实现“我们对您个人信息的管理和使用”部分所述目的，或为履行我们在《钱金出行用户注册协议》或本隐私政策中的义务和行使我们的权利，向钱金出行的关联方、合作伙伴或代表钱金出行履行某项职能的第三方（例如代表我们发出推送通知的通讯服务商、处理银行卡的支付机构等）分享您的个人信息。
      </div>
      <div>
        4、如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能产生的权利纠纷。
      </div>
      <div>
        5、只有共享您的信息，才能提供您需要的服务，或处理您与他人的纠纷或争议。
      </div>
      <div>
        6、您出现违反中国有关法律、法规规定或者违反您与钱金出行签署的相关协议（包括在线签署的电子协议）或违反相关钱金出行平台规则时需要向第三方披露的情形。
      </div>

      <div>7、为维护钱金出行及其关联方或其他钱金出行用户的合法权益。</div>
      <div>
        随着我们业务的发展，我们及我们的关联方有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移。我们将在转移前通知您。
      </div>
      <div class="title_bos">四、您个人信息的安全</div>
      <div>
        钱金出行严格保护您的个人信息安全。我们使用各种制度、安全技术和程序等措施来保护您的个人信息不被未经授权的访问、篡改、披露或破坏。如果您对我们的个人信息保护有任何疑问，请联系我们的客服。
      </div>
      <div>
        在通过钱金出行网站与第三方进行网上商品或服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址等。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是你的账户及密码发生泄露，请您立即联络我们的客服，以便我们采取相应措施。
      </div>

      <div class="title_bos">五、访问和更新您的个人信息</div>
      <div>
        您可以在“会员中心”页面的“账户设置”菜单中查阅您提交给钱金出行的所有个人信息，你也可通过上述途径更新除实名认证信息之外的其他个人信息（您的实名认证信息是您通过实名认证时使用的姓名和身份证信息），如您需要变更您的实名认证信息，您可拨打服务热线申请变更。
      </div>
      <div class="title_bos">六、Cookie 及网络 Beacon的使用</div>
      <div>1、Cookie的使用</div>
      <div>
        Cookie是由网页服务器存放在您的访问设备上的文本文件。指定给您的Cookie
        是唯一的，它只能被将Cookie发布给您的域中的Web服务器读取。 钱金出行使用
        Cookie
        来帮助您实现您的联机体验的个性化，使您在钱金出行及其关联方获得更轻松的访问体验。例如，Cookie
        会帮助您在后续访问钱金出行网站时调用您的信息，简化记录您填写个人信息（例如一键登录等）的流程；为您提供安全购物的偏好设置；帮助您优化对广告的选择与互动；保护您的数据安全等。
      </div>
      <div>
        您有权接受或拒绝
        Cookie。大多数浏览器会自动接受Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝
        Cookie。如果选择拒绝
        Cookie，那么您可能无法完全体验所访问的钱金出行网站或某些服务的全部功能。
      </div>

      <div>2、网络Beacon的使用</div>
      <div>
        钱金出行网页上常会包含一些电子图象（称为"单像素" GIF 文件或 "网络
        beacon"），它们可以帮助网站计算浏览网页的用户或访问某些cookie。钱金出行使用网络beacon的方式有：
      </div>
      <div>
        （1）钱金出行通过在钱金出行网站上使用网络beacon，计算用户访问数量，并通过访问
        cookie 辨认注册用户。
      </div>
      <div>
        （2）钱金出行通过得到的cookie信息，可以在钱金出行网站提供个性化服务。
      </div>
      <div class="title_bos">七、未成年人的个人信息保护</div>
      <div>
        钱金出行非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用钱金出行服务前，应事先取得您家长或法定监护人的书面同意。钱金出行根据国家相关法律法规的规定保护未成年人的个人信息。
      </div>
      <div class="title_bos">八、通知和修订</div>
      <div>
        为给你提供更好的服务，钱金出行的业务将不时变化，本隐私政策也将随之调整。钱金出行会通过在钱金出行网站、移动端上发出更新版本并提醒您相关内容的更新，也请您访问钱金出行以便及时了解最新的隐私政策。如果您对于本隐私政策或在使用钱金出行服务时对于您的个人信息或隐私情况有任何问题，请联系钱金出行客服并作充分描述，钱金出行将尽力解决。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goback() {
      this.$router.go(-1); //返回上一层
    },
  },
};
</script>

<style lang="scss" scoped>
.ertgf_box {
  padding: 1rem;
  .title {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  .title_bos {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
